import React from "react";
import "styles.scss";
import { Card } from "components/Card";
import headerImage from "assets/images/drone_hero_presentation.jpg";

export class Main extends React.Component<any, any> {
  render() {
    return (
      <div className="Main">
        <Card>
          <img src={headerImage} alt="Lorem ipsum" />
        </Card>
        <Card>
          <div className="Card__Text">
            <h1>Cliff Yuan Li</h1>
            <h2>Robotics Engineer</h2>
            <p>Lorem ipsum</p>
          </div>
        </Card>
      </div>
    );
  }
}
