import React from "react";
import ReactGA from "react-ga";
import "styles.scss";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { LoadingCircle } from "components/LoadingCircle";
import { Main } from "pages/Main";

interface AppState {
  isLoading: boolean;
}

export class App extends React.Component<any, AppState> {
  constructor(props: any) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("UA-168004480-1");
      ReactGA.pageview("/");
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="App">
        <Header />
        {this.state.isLoading ? <LoadingCircle /> : <Main />}
        <Footer />
      </div>
    );
  }
}
