import React from "react";

export class Footer extends React.Component<any, any> {
  render() {
    return (
      <footer className="Footer">
        <small>
          <span>Built with ReactJS, TypeScript, and Sass</span>
          <span>&copy; {new Date().getFullYear()} Cliff Yuan Li</span>
        </small>
      </footer>
    );
  }
}
